import {deleteJSON, getJSON} from "../api/http";
import {endpoints} from "../api/endpoints";
import {useQuery, useQueryClient} from "react-query";
import {IAdmins} from "../interfaces/IAdmins";
import {useContext} from "react";
import {LoginStateContext} from "../shared/components/LoginState";
import {LocalStorageService} from "../services/LocalStorageService";

export const getAdmin = async (recordId: Number) => getJSON(endpoints.admins.record.replaceAll('{id}', recordId.toString()));

export const useAdmin = (recordId: Number) => {
    const {dispatch} = useContext(LoginStateContext),
        {setLastError} = LocalStorageService(),
        {data, isLoading, error} = useQuery(
            `admin_${recordId}`,
            () => getAdmin(recordId),
            {
                onError: async () => {
                    setLastError('Brak uprawnień');
                    await deleteJSON(endpoints.auth);
                    dispatch({type: 'NOT_LOGGED'});
                },
                retry: false,
                staleTime: Infinity,
                suspense: true
            }
        );

    return {
        isLoading,
        data: data?.data,
        error
    };
};

const getAdmins = async (stringParams: string) =>
    getJSON(
        [endpoints.admins.list, stringParams && "?", stringParams].join("")
    );


export const useAdmins = (props: IAdmins) => {
    const limit = props._limit ? '_limit=' + props._limit : '',
        order = props._order ? '_order=' + props._order : '',
        orderBy = props._orderBy ? '_orderby=' + props._orderBy : '',
        page = props._page ? '_page=' + props._page : '',
        {dispatch} = useContext(LoginStateContext),
        {setLastError} = LocalStorageService(),
        {data, isLoading, error} = useQuery(
            `tasks_${props._page}_${props._order}_${props._orderBy}_${props.responsibile}`,
            () => getAdmins([page, limit, order, orderBy].filter(Boolean).join('&')),
            {
                onError: async () => {
                    setLastError('Brak uprawnień');
                    await deleteJSON(endpoints.auth);
                    dispatch({type: 'NOT_LOGGED'});
                },
                retry: false,
                staleTime: Infinity,
                suspense: true
            }
        );

    return {
        isLoading,
        data: data?.data,
        error
    };
};