import {useMutation, useQuery, useQueryClient} from "react-query";
import {endpoints} from "../api/endpoints";
import {deleteJSON, getJSON, putJSON} from "../api/http";
import {useContext, useState} from "react";
import {restructureDate} from "../shared/utils";
import {taskStatuses} from "../staticData/taskStatuses";
import {getUser, useUser} from "./useUser";
import {LoginStateContext} from "../shared/components/LoginState";
import {LocalStorageService} from "../services/LocalStorageService";

const getTask = async (recordId: Number) => getJSON(endpoints.task.record.replaceAll('{id}', recordId.toString()));

export const useTask = (recordId: Number) => {
    const {dispatch} = useContext(LoginStateContext),
        {setLastError} = LocalStorageService(),
        {data, isLoading, error} = useQuery(
            `task_${recordId}`,
            () => getTask(recordId).then(async (task) => {
                if (task.data) {
                    const userData = await getUser(task.data.userid);
                    task.data.allTaskStatuses = taskStatuses;
                    task.data.creationdateString = restructureDate(task.data.creationdate);
                    task.data.damagedateString = restructureDate(task.data.damagedate);
                    task.data.fixfinishdateString = restructureDate(task.data.fixfinishdate);
                    task.data.fixstartdateString = restructureDate(task.data.fixstartdate);
                    task.data.moddateString = restructureDate(task.data.moddate);
                    task.data.taskNumber = task.data.damageno + '/' + task.data.month.padStart(2, '0') + '/' + task.data.year;
                    userData.data.phone = userData.data.phone1 || userData.data.phone2 || userData.data.phone3;
                    task.data.userData = userData.data;
                } else if (!task) {
                    await deleteJSON(endpoints.auth);
                    setLastError('Brak uprawnień');
                    dispatch({type: 'NOT_LOGGED'});
                }
                return task;
            }),
            {
                onError: async () => {
                    setLastError('Brak uprawnień');
                    await deleteJSON(endpoints.auth);
                    dispatch({type: 'NOT_LOGGED'});
                },
                retry: false,
                staleTime: Infinity,
                suspense: true
            }
        );

    return {
        isLoading,
        data: data?.data,
        error
    };
};

const changeTask = async (data: any) =>
    putJSON(endpoints.task.record.replaceAll('{id}', data.id.toString()), {
        history_entry: data.description,
        status: data.status
    });

export const useChangeTask = () => {
    const queryClient = useQueryClient(),
        [errors, setErrors] = useState<string[]>([]),
        {mutate, isLoading, isSuccess, isError, reset} = useMutation(
            changeTask,
            {
                onSuccess: () => {
                },
                onError: (error: any) => {
                    alert(error?.data.message);
                    setErrors(Object.values(error?.data.message || {}));
                },
                onSettled: () => {
                    queryClient.invalidateQueries("task");
                }
            }
        );

    return {
        isLoading,
        isSuccess,
        isError,
        errors,
        data: mutate,
        resetForm: reset,
    };
};

const changeStatus = async (data: any) => {
    let additionalData = {};
    if (data.status == 2) {
        let admins: any[] = [];
        data.admins.map((admin: any) => {
            admins.push(admin.id);
        });
        additionalData = {
            Admins: admins,
            notify_firmpositions_id: 2
        }
    } else if (data.status === 3) {
        let admins: any[] = [],
            adminsResponsible: any[] = [];
        data.admins.map((admin: any) => {
            admins.push(admin.id);
        });
        data.adminsResponsible.map((admin: any) => {
            adminsResponsible.push(admin.id);
        });
        additionalData = {
            Admins: admins,
            AdminsResponsible: adminsResponsible,
            fixfinishdate: data.fixfinishdate,
            fixstartdate: data.fixstartdate
        }
    } else if (data.status === 4) {
        additionalData = {
            fixfinishdate: data.fixfinishdate
        }
    }
    return putJSON(endpoints.task.record.replaceAll('{id}', data.id.toString()), {
        ...additionalData,
        history_entry: data.description,
        status: data.status
    });
}

export const useChangeStatus = (successCallback?: Function) => {
    const queryClient = useQueryClient(),
        [errors, setErrors] = useState<string[]>([]),
        {mutate, isLoading, isSuccess, isError, reset} = useMutation(
            changeStatus,
            {
                onSuccess: () => {
                    if (successCallback) {
                        successCallback();
                    }
                },
                onError: (error: any) => {
                    let message = '';
                    Object.keys(error?.data.errors).map((errorMsg) => {
                        message += error?.data.errors[errorMsg] + '\n';
                    });
                    alert(message);
                    setErrors(Object.values(error?.data.message || {}));
                },
                onSettled: () => {
                    queryClient.invalidateQueries("task");
                }
            }
        );

    return {
        isLoading,
        isSuccess,
        isError,
        errors,
        data: mutate,
        resetForm: reset,
    };
};
