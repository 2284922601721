import { FC, useState } from "react";
import { ITaskOption } from "../../interfaces/ITask";
import { taskStatuses } from "../../staticData/taskStatuses";
import { Tasks } from ".";

export const TaskHeader: FC<ITaskOption> = (props) => {
    const [taskStatusSelectClick, setTaskStatusSelectClick] = useState(false),
        toggleStatusSelect = (isVisible: boolean) => {
            setTaskStatusSelectClick(isVisible);
        },
        changeStatus = (status: boolean) => {
            toggleStatusSelect(false);
            props.changeStatus(status);
        },
        clickOutside = (target: any) => {
            if (target.className && typeof target.className == 'string' && !target.className.includes('task-dropdown__item') && taskStatusSelectClick) {
                setTaskStatusSelectClick(false);
            }
        };

    let toggleClassName = `task-dropdown ${taskStatusSelectClick ? 'task-dropdown--toggled' : ''}`;

    return (
        <div className="task-screen__header" onClick={(event) => clickOutside(event.target)}>
            <div className="actions actions--baseline">
                <div className="actions__left">
                    <div className="task-screen__status">
                        {props.showStatus && (
                            <>
                                <div className="status-text">
                                    Status
                                </div>
                                <div className={toggleClassName}>
                                    {taskStatuses.filter((taskStatus: any) => taskStatus.id == props.status).map((taskStatus: any) => (
                                        <button type="button" className="task-dropdown__value" onClick={() => toggleStatusSelect(!taskStatusSelectClick)} key={taskStatus.id}>
                                            {taskStatus.name}
                                            <span className="task-dropdown__icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                    <g transform="translate(-131 -98)">
                                                        <path d="M7,10l3.333,3.333L13.667,10Z" transform="translate(128.667 94.667)" fill="#fff" fillRule="evenodd" />
                                                        <rect width="16" height="16" transform="translate(131 98)" fill="none" />
                                                    </g>
                                                </svg>
                                            </span>
                                        </button>
                                    ))}
                                    <div className="task-dropdown__items">
                                        {taskStatuses.map((taskStatus: any) => (
                                            <button type="button" className="task-dropdown__item" onClick={() => changeStatus(taskStatus.id)} key={taskStatus.id}>
                                                {taskStatus.name}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="actions__right">
                    <div className="task-screen__total">
                        Zadań&nbsp;
                        <span>
                            {props.tasks && props.tasks.total ? props.tasks.total : 0}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
