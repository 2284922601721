import { FC, useEffect, useState } from 'react';
import { IChangeStatusComponent } from "../../interfaces/Components/IChangeStatusComponent";

export const ChangeStatusModal: FC<IChangeStatusComponent> = (props: IChangeStatusComponent) => {
    const
        [isChangeStatusModalVisible, setIsChangeStatusModalVisible] = useState<Boolean>(props.isChangeStatusModalVisible),
        [selectedStatus, setSelectedStatus] = useState<number>(props.selectedStatus),
        [fixstartdate, setFinishstartDate] = useState<string>(''),
        [fixfinishdate, setFixfinishDate] = useState<string>(''),
        [statusDescription, setStatusDescription] = useState<string>(''),
        changeStatus = () => {
            props.changeStatus({
                description: statusDescription,
                fixstartdate: fixstartdate,
                fixfinishdate: fixfinishdate
            });
            setIsChangeStatusModalVisibleWithParent(!isChangeStatusModalVisible);
        },
        changeFixStartDate = (val: any) => {
            setFinishstartDate(val)
        },
        changeFixfinishDate = (val: any) => {
            setFixfinishDate(val)
        },
        changeStatusDescription = (val: any) => {
            setStatusDescription(val);
        },
        setIsChangeStatusModalVisibleWithParent = (val: Boolean) => {
            setIsChangeStatusModalVisible(val);
            props.setIsChangeStatusModalVisible(val);
        },
        showAdminList = () => {
            props.setIsChangeAdminModalVisible(true);
        },
        showAdminResponsibleList = () => {
            props.setIsChangeAdminResponsibleModalVisible(true);
        };

    useEffect(() => {
        setSelectedStatus(props.selectedStatus);
    }, [props.selectedStatus]);

    useEffect(() => {
        setIsChangeStatusModalVisible(props.isChangeStatusModalVisible);
    }, [props.isChangeStatusModalVisible]);

    function convertDateTime(input: string): string {
        const [date, time] = input.split('T');
        
        const formattedTime = `${time}:00`;
        
        const formattedDateTime = `${date} ${formattedTime}`;
        
        return formattedDateTime;
    }

    return (
        <>
            {isChangeStatusModalVisible && (
                <div className="white-opacity white-opacity--first">
                    <div className="white-opacity__wrapper">
                        <div className="modal">
                            <div className="modal__title">
                                Zmiana statusu
                            </div>
                            <div className="modal__body">
                                {(selectedStatus == 3 || selectedStatus == 2) && (
                                    <button className="blue-btn" type="button" onClick={() => showAdminList()}>
                                        <span className="blue-btn__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                                <path
                                                    d="M8 10H5V7H3v3H0v2h3v3h2v-3h3v-2zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 5 18 5c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86s-.34 2.04-.9 2.86c.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 5 13 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16c.83.73 1.38 1.66 1.38 2.84v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 13c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z" />
                                            </svg>
                                        </span>
                                        Powiadom o zleceniu ({props.countAdmins})
                                    </button>
                                )}
                                {selectedStatus == 3 && (
                                    <button className="blue-btn" type="button" onClick={() => showAdminResponsibleList()}>
                                        <span className="blue-btn__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                                <path
                                                    d="M8 10H5V7H3v3H0v2h3v3h2v-3h3v-2zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 5 18 5c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86s-.34 2.04-.9 2.86c.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 5 13 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16c.83.73 1.38 1.66 1.38 2.84v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 13c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z" />
                                            </svg>
                                        </span>
                                        Odpowiedzialni za zlecenie ({props.countAdminsResponsible})
                                    </button>
                                )}
                                {selectedStatus == 3 && (
                                    <div className="date-field">
                                        <div className="date-field__label">
                                            Data rozpoczęcia
                                        </div>
                                        <input type="datetime-local" className="date-field__input"
                                            onChange={(event) => changeFixStartDate(convertDateTime(event.target.value))} />
                                    </div>
                                )}
                                {(selectedStatus == 3 || selectedStatus == 4) && (
                                    <div className="date-field">
                                        <div className="date-field__label">
                                            Data zakończenia
                                        </div>
                                        <input type="datetime-local" className="date-field__input"
                                            onChange={(event) => changeFixfinishDate(convertDateTime(event.target.value)) } />
                                    </div>
                                )}
                                <div className="field">
                                    <input type="text" placeholder="Podaj powód zmiany statusu" className="field__input" maxLength={200} value={statusDescription}
                                        onChange={(event) => changeStatusDescription(event.target.value)} />
                                </div>
                            </div>
                            <div className="modal__footer">
                                <button className="blue-btn" type="button" onClick={() => setIsChangeStatusModalVisibleWithParent(!isChangeStatusModalVisible)}>
                                    Anuluj
                                </button>
                                <button className="blue-btn" type="button" onClick={() => changeStatus()}>
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
