import { FC, useState, useEffect } from 'react';
import { useTasks } from '../../hooks/useTasks';
import { Task } from './task';
import { TaskHeader } from './header';
import { TaskFooter } from './footer';
import { Footer, Header } from '../../shared/components';
import { ITasks } from "../../interfaces/ITask";
import { useGetLoggedUser } from "../../hooks/useAuth";
import { LocalStorageService } from "../../services/LocalStorageService";

export const Tasks: FC = () => {
    const { setStatusLS, getStatusLS } = LocalStorageService(),
        [status, setStatus] = useState<number>(getStatusLS() != 0 ? getStatusLS() : 1),
        [address, setAddress] = useState<String>(''),
        [showStatus, setShowStatus] = useState(true),
        [queryWithUserId, setQueryWithUserId] = useState(true),
        [page, setPage] = useState(1),
        [listTask, setListTask] = useState<ITasks>(),
        [isParsed, setIsParsed] = useState(false),
        [recordId, setRecordId] = useState<number>(0),
        [isLoading, setIsLoading] = useState<Boolean>(true),
        user = useGetLoggedUser(),
        changeStatus = (status: number) => {
            setStatusLS(status);
            setStatus(status);
        },
        changeSearchInput = (search: String) => {
            setAddress(search);
        },
        menuChange = (addUserId: Boolean, newStatus?: Array<number>) => {
            setShowStatus(true);
            if (newStatus) {
                setShowStatus(false);
            }
            setQueryWithUserId(!!addUserId);
        },
        tasks = useTasks({
            _limit: 20,
            _order: 'desc',
            _orderBy: 'NewHistory,id',
            _page: page,
            address: address,
            status: status,
            userid: user.data?.admin_id,
            queryWithUserId: queryWithUserId
        }),
        loadMore = () => {
            setPage(page + 1);
            setIsParsed(false);
        },
        redirectToTask = (task: any) => {
            setRecordId(task.id);
        };

    useEffect(() => {
        setIsParsed(false);
        setListTask({
            list: [],
            total: 0
        });
    }, [address, status]);

    useEffect(() => {
        if (tasks.data && tasks.data.total > 0 && !isParsed) {
            const list = listTask ? listTask.list : [];
            // const list = listTask && tasks.data.list.length >= listTask?.list.length ? listTask.list : [];
            setIsParsed(true);
            setListTask({
                list: [
                    ...list,
                    ...tasks.data.list
                ],
                total: tasks.data.total
            });
        }
        setIsLoading(tasks.isLoading);
    }, [tasks]);

    return (
        <div className={recordId ? 'task-info-screen' : 'task-screen'}>
            <Header menuChange={menuChange} changeSearchInput={changeSearchInput} recordId={recordId} setRecordId={setRecordId} user={user.data} />
            <div className={`container${recordId ? ' container--main' : ''}`}>
                {!recordId && (
                    <TaskHeader status={status} tasks={listTask} changeStatus={changeStatus} showStatus={showStatus} />
                )}
                {!recordId && listTask && !isLoading && listTask.list && (
                    <div className="task-screen__tasks">
                        <div className="task-screen__tasks">
                            {listTask && listTask.list ? listTask.list.map((task: any) => (
                                <div className="task-item-drag-area" key={task.id} onClick={() => redirectToTask(task)}>
                                    <div className="task-item-drag-area-div">
                                        <div className="task-item task-item--info">
                                            <div className="task-item__icon">
                                                <div className={`task-icon task-icon--status${task.status}`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29.819" viewBox="0 0 30 29.819">
                                                        <path
                                                            d="M30.563,25.473,18.172,13.082a8.739,8.739,0,0,0-2.042-9.4A9,9,0,0,0,6.054,1.917l5.855,5.855L7.824,11.857,1.833,6A8.775,8.775,0,0,0,3.6,16.078,8.739,8.739,0,0,0,13,18.12L25.389,30.511a1.316,1.316,0,0,0,1.906,0l3.132-3.132a1.226,1.226,0,0,0,.136-1.906Z"
                                                            transform="translate(-0.956 -1.1)" fill="#fff" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="task-item__description">
                                                <div className="task-item__date">{task.damageno + '/' + task.month.padStart(2, '0') + '/' + task.year}</div>
                                                <div className="task-item__address">{task.affectedadreses.map((affectedAddress: string, addressIndex: number) => (
                                                    <div key={addressIndex}>
                                                        {affectedAddress}
                                                    </div>
                                                ))}</div>
                                                <div className="task-item__city">{task.city}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : ''}
                        </div>
                    </div>
                )}
                {!recordId && listTask && listTask.list && listTask.total > listTask.list.length && (
                    <button onClick={() => loadMore()} className="load-more">
                        Załaduj więcej
                    </button>
                )}
                {!recordId && (
                    <TaskFooter isLoading={isLoading} />
                )}
                {recordId != 0 && (
                    <Task recordId={recordId} setIsLoading={setIsLoading} /* onStatusChange={tasks.refetch} *//>
                )}
            </div>
            <Footer />
        </div>
    );
};
